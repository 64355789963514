import { FC, useEffect } from "react"

import config from "../../config.json"

import { WrappedBase } from "../shared-components/Shared/WrappedBase"

import { getCookie, removeAuthCookie } from "../utils/auth0"

const UnsetToken: FC = () => {
  useEffect(() => {
    if (
      window !== undefined &&
      document !== undefined &&
      localStorage !== undefined
    ) {
      localStorage.removeItem("habito/token")

      const redirectURI = getCookie("authFlows_logoutContinuation") || ""

      removeAuthCookie("authFlows_logoutContinuation")

      window.location.replace(redirectURI)
    }
  })

  return (
    <WrappedBase
      metaTitle="Unset token"
      metaDescription=""
      canonicalUrl="https://www.habito.com/unset-token"
      noIndex={true}
      pageName="unset token"
      intercom={false}
      config={config}
    />
  )
}

export default UnsetToken
